import React, { useState } from 'react';
import axios from 'axios';
import {getConfig} from './config';
import {useUserManager} from './useUserManager';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider  } from '@mui/material/styles';

function SignInForm({  onAuthenticate }) {
  const config = getConfig();
  const userManager = useUserManager();
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    const formData = new FormData(event.currentTarget);
    try {
      //const response = await axios.post(`${config.api_domain}/api/token/`, { email, password });
	  const data = await userManager.login(formData.get('email'), formData.get('password'));
      console.log(data);
      if (data && data.access) {
        // Assuming the API returns a token or similar authentication measure
        // Store it appropriately (e.g., in localStorage for web apps)
        // localStorage.setItem('authToken', response.data.token);

        onAuthenticate(true);
      } else {
        setError('Login failed');
      }
    } catch (err) {
      setError('Invalid email or password');
    }
  };

const defaultTheme = createTheme();

  return (
      <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
      sx={{
                backgroundColor:'',
                      my:3,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',

      }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main'  }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1  }}>


      {error && <div style={{ color: 'red' }}>{error}</div>}


      <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
      <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
      <Button type="submit" variant={"outlined"}>Sign In</Button>

                </Box>
              </Box>
            </Container>
          </ThemeProvider>
  );
}

export default SignInForm;
