import * as React from 'react';
import Annotator from "./annotator";

export default function AnnotateScreenDialog({row, onSave, project_id}) {


    return (
        <React.Fragment>
            <Annotator img={row} imageUrl={row.image_url} image_id={row.id} onSave={onSave} project_id={project_id}/>
        </React.Fragment>
    );
}
