import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import { fabric } from 'fabric';
import {addRect, getRandomColor, setUpRect} from './canvas-functions';
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {Fab, FormControl, ListItem, ListItemAvatar, Radio, RadioGroup, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import {MuiColorInput} from "mui-color-input";
import Button from "@mui/material/Button";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from '@mui/icons-material/Folder';
import Avatar from "@mui/material/Avatar";

import {getConfig } from '../config';
import {useUserManager} from '../useUserManager';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const AddLabelComponent = ({ project_id, setLoading, labelAdded }) => {
    const userManager = useUserManager();
    const config = getConfig();
    const [labelName, setLabelName] = useState("");
    const handleNameChange = (e) => {
        setLabelName(e.target.value)
    };
    useEffect(() => {

    })


    const [colorValue, setColorValue] = React.useState('#ffffff')

    const handleColorChange = (newValue) => {
        setColorValue(newValue)
        console.log(colorValue)
    }

    async function createLabel(){
        setLoading(true);

        var req = {
            'color_hex': colorValue.replace("rgb(", "").replace(")", ""),
            'label': labelName,
            'project_id': project_id
        };
        console.log(req);
        userManager.secureFetch(config.api_domain + "/api/labels/",{
            "method": "POST",
            "headers": {
                "content-type": "application/json",
            },
            body: JSON.stringify(req)
        }).then((response)=>{
            if(response.status > 199 && response.status < 299){
                labelAdded()
            }
            setLoading(false)
        });
    };

    const handleLabelAdd= async () => {
        createLabel();
    };


    return (
        <div>

                            <TextField
                                autoFocus

                                margin="dense"
                                id="name"
                                label="Label"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={labelName}
                                onChange={handleNameChange}
                            />

                            <MuiColorInput value={colorValue} onChange={handleColorChange} format={'rgb'} isAlphaHidden />

                            <Button variant="contained" autoFocus color="inherit" onClick={handleLabelAdd}>
                                Add Label
                            </Button>

        </div>
    );
};

export default AddLabelComponent
