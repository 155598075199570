import React, { useState } from 'react';
import { Button, Modal, Box, TextField, Typography } from '@mui/material';
import {useUserManager } from './useUserManager';
import {getConfig} from './config';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};


const ProjectModal = ({created}) => {

  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const config = getConfig();
  const userManager = useUserManager();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    userManager.secureFetch(config.api_domain + '/api/projects/', { method:"POST", body: JSON.stringify({name }), headers:{"Content-Type": "application/json"}}).then((response)=>{
    if (response.status === 201) {
      console.log("project added");
      handleClose();
      created();
      // Additional logic to update UI
    } else {
      // Handle other statuses or errors
    }
    });
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>Add Project</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add a New Project
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Project Name"
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Button onClick={handleSubmit} style={{ marginTop: '20px' }}>Submit</Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ProjectModal;
