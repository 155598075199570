import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack'
interface ImageNumberButtonProps {
  imageUrl: string;
  number: number;
  buttonText: string;
  onButtonClick?: () => void;
}

const ImageNumberButton: React.FC<ImageNumberButtonProps> = ({
  imageUrl,
  number,
  buttonText,
  onButtonClick,
}) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: 380, // set width to match maxWidth
        height: 225, // Define a height for the box
      }}
    >
<Stack
  direction="column"
  justifyContent="space-between"
  alignItems="center"
  sx={{height:'100%'}}
>
      <Typography
        variant="h6"
        component="div"
        sx={{
            mt:2,
          color: 'white',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          padding: '4px 8px',
          borderRadius: 4,
        }}
      >
        {number}
      </Typography>
      <Button sx={{mb:2}}

        variant="contained"
        onClick={onButtonClick}
      >
        {buttonText}
      </Button>
</Stack>
    </Box>
  );
};

export default ImageNumberButton;

