import React, {Fragment, useEffect, useState, useContext, useRef} from "react";
import { borders } from '@mui/system';
import { getConfig } from "./config";
import moment from "moment";
import { useUserManager} from "./useUserManager";
import {Box, Grid, IconButton, Slide, Stack, Typography} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ProjectList from './projectList';

export const Home = () => {
    const theme = useTheme();
    const userManager = useUserManager();

    return (

        <Stack spacing={0}>
 			<ProjectList />
        </Stack>);
};
