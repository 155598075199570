const isProduction = process.env.NODE_ENV === 'production';

export function getConfig() {
    // Configure the audience here. By default, it will take whatever is in the config
    // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
    // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
    // don't have an API).
    // If this resolves to `null`, the API page changes to show some helpful info about what to do
    // with the audience.
    return {
        api_domain: isProduction ? "https://api.annoset.com" : "https://httpai5002.h.svrz.com",
        file_types: ["JPG", "PNG", "GIF", "JPEG"]
    };
}
