import React, { Component, useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './Layout';
import {useUserManager} from "./useUserManager";
import { userManager } from "./UserManagerContext";
import {Login} from "./Login";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from './themeContext';
import {alpha, createTheme, useTheme} from "@mui/material/styles"; // Import the ThemeProvider
import { green, purple } from '@mui/material/colors';
import {Typography} from "@mui/material";
import {Helmet} from "react-helmet";
import axios from 'axios';
import {RegistrationForm} from './RegistrationForm';
import SignInForm from './signinForm';
axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const data = await userManager.refreshToken();
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

const App = () => {

    const userManager = useUserManager();
    const theme = useTheme();
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [auth, setAuth] = useState(false);
    const [initializedPage, setInitializedPage] = useState(false);
    let val = true;

    const profileCb = () =>{
        loadProfileStatus();
    }
    const loadProfileStatus = () => {
        setLoading(true);
		console.log('checking for auth');
        if(userManager.isAuthenticated()){

                setAuth(true);
                setLoading(false);
                setInitializedPage(true);
        }else{
userManager.getnewAccessTokenIfPossible()
  .then(response => {
    console.log(response.message); // This logs the outcome of the token refresh attempt or status
    if (response.success) {

                setAuth(true);
                setLoading(false);
                setInitializedPage(true);
      // The token is valid or was successfully refreshed
      // Proceed with the application logic that requires a valid token
    } else {

            setLoading(false);
            setAuth(false);
            setInitializedPage(true)
            console.log('auth is false');
      // Handle the failure case, maybe redirect to login or show an error message
    }
  })
  .catch(error => {
    console.error('Error during token refresh:', error);
    // Handle any unexpected errors here
  });
        }
    };
    useEffect(() => {
            loadProfileStatus();
    }, [userManager]);



    if (!initializedPage || loading === true) {
        return (
            <h1>Loading</h1>
        );
    }

    const loginTheme = createTheme(theme, {
        palette: {
            primary: {
                main: purple[500],
            },
            secondary: {
                main: green[500],
            },
        },
        text: {
            primary: 'rgba(60, 72, 88, 1)',
            secondary: 'rgba(132, 146, 166, 1)',
            disabled: 'rgba(60, 72, 88, 0.38)',
            hint: 'rgba(60, 72, 88, 0.38)',
        },
        typography: {
            text: {
                color: 'red',
                primary: 'red',
                secondary: alpha("#fff", 0.6)
            },
            body1: {
                fontWeight: 500,
                color: 'red',
                primary: 'red',
            }
        },
    });

   const authChanged = (val) => {
     setAuth(val);
   };
    if(!auth){
        val = false;
        return (
            <ThemeProvider theme={loginTheme}>
                <Login authChanged={authChanged}/>
            </ThemeProvider>)
    }

    val = false;


    return (
        <ThemeProvider>
            <Layout>

                <Routes>
                    {AppRoutes.map((route, index) => {
                        const {element, requireAuth, ...rest} = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                </Routes>
            </Layout>
        </ThemeProvider>
    );

}
export default App;
