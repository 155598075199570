import * as React from 'react';
import {useState, useEffect} from 'react';
import {Button, Box, TextField, Card, CardActions, Stack, CardContent, Grid, Typography} from "@mui/material";
import Select from '@mui/material/Select';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import {getConfig} from './config'
import ReadOnlyTextField from './ReadOnlyTextField.js'
import {useUserManager} from './useUserManager'
import Highlight from 'react-highlight'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {CopyToClipboard} from 'react-copy-to-clipboard';
const theme = createTheme({
    typography: {
        body1: {
            fontSize: 18,
            marginBottom:'1.8em'
        }
    }
});

const exportCommand = `curl -s 'your_url_here' | python3 -c "
import sys, json;
data = json.load(sys.stdin);
if 'datasets' in data:
    datasets = data['datasets'];
    if 'train' in datasets and datasets['train']:
        with open('train.json', 'w') as f:
            json.dump(datasets['train'], f);
    if 'validation' in datasets and datasets['validation'] and len(datasets['validation']) > 0:
        with open('validation.json', 'w') as f:
            json.dump(datasets['validation'], f);
    if 'test' in datasets and datasets['test'] and len(datasets['test']) > 0:
        with open('test.json', 'w') as f:
            json.dump(datasets['test'], f);
"`

const ExportDetails = () => {
    const userManager = useUserManager();
    const config = getConfig();
    const [selectedState, setSelectedState] = useState(null);
    const [projects, setProjects] = useState([]);
    const [loading, setIsLoading] = useState(true);
	const [exportCode, setExportCode] = useState(null);

    const renderCommand = (project) => {
        if(project){
            let url = "https://api.annoset.com/api/datasets/get?key=" + project.hashkey
            setUrlValue(url);
			setExportCode(exportCommand.replace(/your_url_here/g, url))
        }
    }
    const selectedStateChanged = (event) => {
        setSelectedState(event.target.value);

        let project = projects.filter(x=>x.id == event.target.value)[0];
        renderCommand(project);
    }
    useEffect(()=>{
        fetchProjects();
    }, []);


    const [urlValue,setUrlValue] = useState("");


  const fetchProjects = async () => {
      try {
       userManager.secureFetch(config.api_domain + '/api/projects/')
.then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setProjects(data);
        setSelectedState(data[0].id)
		new Promise((resolve)=>{

          renderCommand(data[0]);
		});
        setIsLoading(false);
      });
        //	console.log(response);
		//	setProjects(response.data);
        //	setIsLoading(false);
      } catch (error) {
		console.log(error);
        setIsLoading(false);
      }
    };

 const [isCopied, setIsCopied] = useState(false);

    return (
        <React.Fragment>
     <Typography variant="h5" component="div" sx={{ marginBottom: '0.5em' }} align={'center'}>

	Export</Typography>
        <Grid container  sx={{mt:2}}>
          <Grid xs={6} sx={{paddingRight:'1em'}}>

           <Typography variant="body1" sx={{marginBottom:'1.5em'}}>
        The export function is designed to integrate with your workflow. Most people building AI models have a pipeline that generates the final model. Automatic retrieval of the data set allows a fully automated training process.
            </Typography>


           <Typography variant="body1" sx={{marginBottom:'1.5em'}}>
        Use the generator to produce a curl command that will download datasets into 3 separate files or call the URL via your own method (e.g., python request).
            </Typography>


           <Typography variant="body1" sx={{marginBottom:'1.5em'}}>
Alternatively, you can integrate the provided URL into your process manually. The token is the identifier and authentication.
            </Typography>


            <Typography variant="h5" sx={{marginBottom:'1.5em'}}>
                Extras
            </Typography>


            <Typography variant="body1" sx={{marginBottom:'1.5em'}}>
        You can add 2 extra paramters to the request.
           </Typography>

          <Typography variant="body1" sx={{marginBottom:'1.5em'}}><strong>t
            </strong>
                - a number from 0.1 to 1, which is representative of a percentage. That percentage is taken from the complete dataset and put into the test segment. 0.1 is 10% and 0.7 is 70%.<br />
        <strong>v</strong> - a number from 0.1 to 1, which will be seen as a percentage divided into the validation dataset.
            </Typography>
            <Typography variant="body1" sx={{marginBottom:'1.5em'}}>Any reminder of the value will be assigned to the train data set. If you set t to 0.2 and v to 0.2, 20% will go to test, 20% will go to validation and 60% will go to train. </Typography>

            <Typography variant="body1" sx={{marginBottom:'1.5em'}}>You can omit both t and v or use on of them.</Typography>

            <Typography variant="body1" sx={{marginBottom:'1.5em'}}>A 400 error is returned in the following conditions:<br />
            <ul>
                <li>If you provide a value less than 0 or greater than 1. </li>
                <li>If you provide both t and v values and they sum to a value greater than 1 you will receive a 400 error. </li>
            </ul></Typography>

            <Typography variant="body1" sx={{marginBottom:'1.5em'}}>If the percentage calculation results in an uneven number or fraction, the values are rounded up. For example, if there are 10 images in the dataset and 25% of them are assigned to the test dataset, 3 images will be assigned. </Typography>
    </Grid>
          <Grid xs={6}>
            <Box sx={{backgroundColor:'#efefef', padding:2}}>
                <div>Chose a project</div>
        <Select sx={{width:'100%'}}
                  value={selectedState}
                  onChange={selectedStateChanged}
                >
        {projects.map((e)=>{
            return (
                <MenuItem key={e} value={e.id}>{e.name}</MenuItem>
            )
        })}
                </Select>

                <Typography variant="h6">Url</Typography>
                <Typography variant="body1" sx={{marginBottom:1}}>You can use this URL in your own requests</Typography>
                <TextField id="outlined-basic" label="Url" value={urlValue}   variant="outlined" sx={{width:'100%'}}
      InputProps={{
        readOnly: true, // Makes the TextField read-only
      }} />
			<Box sx={{overflow:'hidden'}}>
			<SyntaxHighlighter language="javascript" style={dark}>
				{exportCode}
			</SyntaxHighlighter></Box>
<CopyToClipboard text={exportCode}
          onCopy={() => {}}>
<Button variant="contained" color="primary">
                    Copy Text
                </Button>
        </CopyToClipboard>

            </Box>
          </Grid>
        </Grid>
        </React.Fragment>
    )
}


export default ExportDetails;;
