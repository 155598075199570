import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {getConfig} from './config'
import {useUserManager} from './useUserManager'
import ProjectModal from './projectCreate';
import {Stack,Box, Button, Card, CardActions, CardContent, Dialog, Grid, Slide, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {FileUploader} from "react-drag-drop-files";
import AnnotateScreenDialog from "./ai/annotatedialog";
import ImageNumberButton from './ImageNumberButton';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ProjectsDetails  = () => {
    const config = getConfig();
    const { id } = useParams();
    const userManager = useUserManager();
    const [project, setProject] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [images, setImages] = useState([]);

    const uploadFile = async (file) =>{

        return new Promise((resolve)=>{
            const formData = new FormData();

            formData.append('file', file[0]);
            formData.append("project_id", id);
            userManager.secureFetch(config.api_domain + '/api/images/upload/', {
                method: 'POST',
                body: formData,
            }).then((response) => {
                console.log(response);
                resolve(response);
            });
        });
    }

    const [open, setOpen] = React.useState(false);
    const [logoId, setLogoId] = React.useState(-1);

    const handleClickOpen = (id, type) => {
        setLogoId(id)
        console.log(id)
        setOpen(true);
    };

    const handleChange = (file) => {
        setImages(prevArray => [...images, file])
        console.log(file[0])
        uploadFile(file) .then(data => {
            console.log('Data received:', data);
        })
            .catch(error => {
                console.error('Error:', error);
            });

        console.log(images);
    };
    const fetchProject = async () => {
        try {
            userManager.secureFetch(config.api_domain + '/api/projects/' + id + '/')
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    setProject(data);
                    setImages(data.images);
                    setIsLoading(false);
                });
            //	console.log(response);
            //	setProjects(response.data);
            //	setIsLoading(false);
        } catch (error) {
            console.log(error);
            setError('Error fetching projects');
            setIsLoading(false);
        }
    };

    const onSaveDialog = (row, type) => {
        setOpen(false);
        console.log('onSaveDialog');
    };

    const handleClose = () => {
        setOpen(false);

        fetchProject()
            // make sure to catch any error
            .catch(console.error);

    };

    useEffect(() => {
        // Replace 'http://yourapi.domain.com' with your actual API domain

        fetchProject().catch(console.error);;
    }, []);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <h2>Project {project.name}</h2>

            <FileUploader handleChange={handleChange} name="file" types={config.file_types} multiple={true} />

<Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
           spacing={0} flexWrap="wrap" sx={{marginTop:'1em'}}
        >
            {project.images.map((image) => (
                    <Box>                   <ImageNumberButton imageUrl={image.thumbnails["400"]}

                        number={image.annotations.length}
                        buttonText="Annotate"
                        onButtonClick={(e)=>handleClickOpen(image, 'logo')} /></Box>            ))}
</Stack>
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                PaperProps={{
                    sx: {
                        width: "100%",
                        maxWidth: "1400px!important",
                    },
                }}
            >

                <AnnotateScreenDialog row={logoId} onSave={onSaveDialog} project_id={id}  />
            </Dialog>
        </div>
    );
};

export default ProjectsDetails;
