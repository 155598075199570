
import { Home } from "./Home";
import ProjectDetails from "./projectDetails";
import ExportDetails from './exportDetails';
import ProjectList from './projectList'
const ConfirmMail = () =>{
	return (
		<div>confirm</div>
	);
}

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },{

    path: '/projects/:id',
    requireAuth: true,
    element: <ProjectDetails />
  },{

    path: '/confirmmail/:id',
    element: <ConfirmMail />

  },{

    path: '/export',
    requireAuth: true,
    element: <ExportDetails />

  },{

    path: '/projects',
    requireAuth: true,
    element: <ProjectList />
  }


];

export default AppRoutes;
