import React, { createContext, useContext, useState, useMemo } from 'react';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

const ThemeContext = createContext();

export const useThemeContext = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
    const [mode, setMode] = useState('light');

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
            mode,
        }),
        [mode]
    );




    const theme = createTheme({
        palette: {
            mode: mode,
            primary: {
                main: '#ff3464'
            },
            text: {
                primary: mode === "light" ? "#000" : "#fff"
            }
        },
        typography: {
            fontSize: 14,
            fontFamily: [
                '-apple-system',
                'system-ui',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            h1: {
                fontFamily: 'Bungee, sans-serif',
            },
            h2: {
                fontFamily: 'Bungee, sans-serif',
            },
            h3: {
                fontFamily: 'Bungee, sans-serif',
            },
            h4: {
                fontFamily: 'Bungee, sans-serif',
            },
            h5: {
                fontFamily: 'Bungee, sans-serif',
            },
        body1: {
            fontSze:20,
        }

    }});

    return (
        <ThemeContext.Provider value={colorMode}>
            <MUIThemeProvider theme={theme}>
                {children}
            </MUIThemeProvider>
        </ThemeContext.Provider>
    );
};
