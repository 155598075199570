import React, {Fragment, useEffect, useState,useContext} from "react";
import { useUserManager} from "./useUserManager";

import './Login.css';
import {Button, Box, Grid, Paper, Stack, Typography} from "@mui/material";
import {RegistrationForm} from "./RegistrationForm";
import SignInForm from "./signinForm";

export const Login= ({authChanged}) => {
    var userManager = useUserManager();

	const loginWithRedirect = () =>{

	};
    const onLoginSuccess = () => {
        authChanged(true)
    };
    const onAuthenticate = (val) => {
        authChanged(val);
    };
    return (

        <Box height="100vh" display="flex" flexDirection="column">
            <Stack  justifyContent="center"
                    alignItems="center" sx={{height:'100%'}} >

            <Grid container spacing={2} sx={{height:'100%'}}>
                <Grid item xs={6} sx={{backgroundColor:'#faebef'}}>

                    <Stack justifyContent="center" alignItems="center" sx={{height:'100%'}}>
                    <Box component="img" src="/header_img.png" sx={{maxWidth:'600px'}}></Box>
                    </Stack>
                </Grid>
                <Grid item xs={6} sx={{px:5}}>
                    <Stack spacing={2} justifyContent="center" alignItems="center" sx={{height:'100%'}}>
                        <Box>
                            <SignInForm onAuthenticate={onAuthenticate} />
                        </Box>

                    </Stack>
                </Grid>

            </Grid>
            </Stack>
            </Box>

    )
};
