import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {getConfig} from './config'
import {useUserManager} from './useUserManager'
import ProjectModal from './projectCreate';
import {Button, Card, CardActions, Stack, CardContent, Grid, Typography} from "@mui/material";
import {Link} from "react-router-dom";
const ProjectsList = () => {
  const config = getConfig();
  const userManager = useUserManager();
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
	const created = () => {
	fetchProjects();
}
 // Replace 'http://yourapi.domain.com' with your actual API domain
  const fetchProjects = async () => {
      try {
       userManager.secureFetch(config.api_domain + '/api/projects/')
.then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setProjects(data);
        setIsLoading(false);
      });
        //	console.log(response);
		//	setProjects(response.data);
        //	setIsLoading(false);
      } catch (error) {
		console.log(error);
        setError('Error fetching projects');
        setIsLoading(false);
      }
    };
  useEffect(() => {
    fetchProjects();
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
     <Typography variant="h5" component="div" sx={{ marginBottom: '0.5em' }} align={'center'}>

	Projects</Typography>
      <Stack justifyContent="center"  alignItems="center" sx={{mb:3}}>  <ProjectModal created={created}/> </Stack>
      {projects.length > 0 ? (
          <Grid container spacing={2}  direction="row"
                justifyContent="center"
                alignItems="center">

            {projects.map((project) => (
                <Grid item xs={6} md={3}>
                  <Card elevation={1} key={project.id}  sx={{ p:{xs:0,md:0}}}>

                    <CardContent sx={{height: 175, p:{xs:0.5,md:2}}}>

                      <Link tag={Button}  to={'/projects/' + project.id}>
                        <Typography component="h5" variant="h6" align={'center'}>{project.name}</Typography>
                      </Link>



                    </CardContent>
                    <CardActions>



                    </CardActions>
                  </Card>
                </Grid>
            ))}
          </Grid>
      ) : (
        <p>No projects found.</p>
      )}
    </div>
  );
};

export default ProjectsList;
